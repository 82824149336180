<template>
  <div>
    <Header 
      title="Gerenciamento de Clientes"
      subtitle="Visualize os últimos usuários cadastrados no site."
    >
      <div class="flex flex-col md:flex-row items-start md:items-end space-y-4 space-x-0 md:space-x-4">
        <Input v-model="searchName" name="search" placeholder="Pesquise pelo nome ou email" class="mt-4 w-full" />
        <input v-model="searchDoc" placeholder="Pesquise pelo CPF/CNPJ" v-mask="['###.###.###-##', '##.###.###/####-##']" type="text" autocomplete="off" name="document" id="document" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
        <input v-model="searchPhone" placeholder="Pesquise pelo telefone" v-mask="['(##) ####-####', '(##) #####-####']" autocomplete="off" type="text" name="phone" id="phone" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
        <button @click="searchUser()" type="button" class="inline-flex items-center p-2 border border-transparent rounded-md shadow-sm text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" /></svg>
        </button>
      </div>
    </Header>
    <div class="max-w-7xl mx-auto mt-3 px-4 sm:px-6 lg:px-8">
      <div class="bg-white shadow overflow-hidden rounded-md">
        <ul class="divide-y divide-gray-200">
          <li @click="userFocus = user, $modal.show('user')" v-for="(user, index) in users" :key="index">
            <div class="flex items-center justify-between px-4 py-4 sm:px-6 hover:bg-gray-50">
              <div class="min-w-0 flex-1">
                <p class="font-medium text-sm text-primary truncate">{{ user.data().Name }}</p>
                <div class="hidden sm:flex sm:items-center text-xs font-medium text-gray-600">
                  <span>{{ user.data().Email }}</span>
                </div>
              </div>  
              <p class="text-xs font-medium text-gray-600">{{ user.data().CreatedAt.toDate() | elapsedTime }}</p>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600 ml-1" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" /></svg>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <t-modal name="user" class="z-45">
      <p class="text-base font-medium text-gray-800">Visualizar Informações</p>
      <div v-if="userFocus.id" class="border-t border-gray-200">
        <dl>
          <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
              Nome
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{ userFocus.data().Name }}
            </dd>
          </div>
          <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
              {{ userFocus.data().Document.length > 14 ? 'CNPJ' : 'CPF' }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{ userFocus.data().Document }}
            </dd>
          </div>
          <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
              Email
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{ userFocus.data().Email }}
            </dd>
          </div>
          <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
              Telefone
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{ userFocus.data().Phone }}
            </dd>
          </div>
        </dl>
      </div>
      <button @click="$router.push(`/admin/pedidos/${userFocus.id}`)" type="submit" class="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
        Ver Pedidos
      </button>
    </t-modal> 
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import { usersColl } from '@/firebase'

export default {
  components: {
    Input: () => import('@/components/form/input'),
    Header: () => import('@/components/layout/header'),
  },

  data() {
    return {
      searchName: '',
      searchDoc: '',
      searchPhone: '',
      users: [],
      userFocus: {}
    }
  },

  mounted() { this.getUsers() },

  methods: {
    async getUsers() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await usersColl.orderBy('CreatedAt', 'desc').limit(30).get()
      .then((result) => { this.users = result.docs })
      .catch(() => { this.$toast.error('Falha ao obter os clientes, tente novamente') })
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async searchUser() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      let tempName, tempDoc, tempEmail, tempPhone
      await usersColl.where('Name', '==', this.searchName).limit(5).get()
      .then((result) => { tempName = result.docs })
      .catch(() => { this.$toast.error('Falha ao obter os clientes, tente novamente') })
      await usersColl.where('Document', '==', this.searchDoc).limit(5).get()
      .then((result) => { tempDoc = result.docs })
      .catch(() => { this.$toast.error('Falha ao obter os clientes, tente novamente') })
      await usersColl.where('Email', '==', this.searchName).limit(5).get()
      .then((result) => { tempEmail = result.docs })
      .catch(() => { this.$toast.error('Falha ao obter os clientes, tente novamente') })
      await usersColl.where('Phone', '==', this.searchPhone).limit(5).get()
      .then((result) => { tempPhone = result.docs })
      .catch(() => { this.$toast.error('Falha ao obter os clientes, tente novamente') })
      this.users = [ ...tempName, ...tempDoc, ...tempEmail, ...tempPhone ]
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    }
  },

  filters: {
    elapsedTime(value) {

      const units = {
        year  : 24 * 60 * 60 * 1000 * 365,
        month : 24 * 60 * 60 * 1000 * 365/12,
        day   : 24 * 60 * 60 * 1000,
        hour  : 60 * 60 * 1000,
        minute: 60 * 1000,
        second: 1000
      }
  
      const rtf = new Intl.RelativeTimeFormat('pt-BR', { numeric: 'auto' })
      const elapsed = new Date(value) - new Date()

      for (var u in units) 
        if (Math.abs(elapsed) > units[u] || u == 'second') 
          return rtf.format(Math.round(elapsed/units[u]), u)
    }
  },
  
  directives: { mask }
}
</script>